import { useAppSelector } from 'hooks/redux';
import { User } from 'interfaces/user';
import { FC } from 'react';
import { selectUser } from 'store/slices/auth';

type ProtectedElementProps = {
  children: JSX.Element;
  roles: string[];
};

const ProtectedElement: FC<ProtectedElementProps> = ({ children, roles }) => {
  const user: User | undefined = useAppSelector(selectUser);

  if (!user?.role || !roles.includes(user.role.name)) {
    // If user role is not authorized for this route, redirect to unauthorized page
    return null;
  }

  return children;
};

export default ProtectedElement;
