import { ColumnFilter, ColumnSort } from '@tanstack/react-table';
import axiosInstance from 'config/axios';
import { FluidSource } from 'interfaces/equipment/fluid-source';
import { PaginationResponse } from 'interfaces/pagination-response';

const BASE_URL = '/v1/equipment/fluid-sources';

export type FluidSourceResponse = PaginationResponse<FluidSource>;

const FluidSourceService = {
  get: (queryParams: {
    pageIndex: number;
    pageSize: number;
    sorting?: ColumnSort[];
    columnFilters: ColumnFilter[];
  }) => {
    return axiosInstance.get<FluidSourceResponse>(BASE_URL, {
      params: queryParams,
    });
  },

  update: (id: string | number, data: Partial<FluidSource>) => {
    return axiosInstance.patch<FluidSource>(`${BASE_URL}/${id}`, data);
  },

  create: (data: Partial<FluidSource>) => {
    return axiosInstance.post<FluidSource>(BASE_URL, data);
  },

  find: (id: string | number) => {
    return axiosInstance.get<FluidSource>(`${BASE_URL}/${id}`);
  },

  delete: (id: string | number) => {
    return axiosInstance.delete<FluidSource>(`${BASE_URL}/${id}`);
  },
};

export default FluidSourceService;
