import { Link } from 'react-router-dom';
import logo from 'assets/images/logo.svg';
import UserMenu from './UserMenu/UserMenu';
import { IoMdSettings } from 'react-icons/io';
import ProtectedElement from 'components/guards/ProtectedElement';
import { ROLE_ADMIN, ROLE_LAB_TECHNICIAN } from 'constants/roles';
import ReactTooltip from 'components/UI/ReactTooltip';
import LinkMenu from './LinkMenu/LinkMenu';
import { LAB_MENU } from 'constants/header-links';

const Header = () => {
  return (
    <header className="top-navbar d-flex justify-content-between" data-testid="header">
      <div className="d-flex">
        <div className="top-navbar__logo-wrapper d-flex justify-content-center align-items-center">
          <Link className="top-navbar__logo-link" to={'/'}>
            <img src={logo} alt="Logo" />
          </Link>
        </div>
        <div className="ms-4">
          <ProtectedElement roles={[ROLE_ADMIN, ROLE_LAB_TECHNICIAN]}>
            <LinkMenu title={LAB_MENU.title} icon={LAB_MENU.icon} items={LAB_MENU.items} />
          </ProtectedElement>
        </div>
      </div>
      <div className="top-navbar__right d-flex pe-4">
        <ProtectedElement roles={[ROLE_ADMIN]}>
          <>
            <Link to={'/settings/users'} className="top-navbar__right--settings">
              <IoMdSettings size={32} data-tooltip-id="header-settings" data-tooltip-content={'Settings'} />
              <ReactTooltip id="header-settings" place="bottom" style={{ fontSize: '13px' }} />
            </Link>
          </>
        </ProtectedElement>
        <UserMenu />
      </div>
    </header>
  );
};

export default Header;
