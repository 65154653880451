import { Header } from '@tanstack/react-table';
import DebouncedInput from 'components/UI/DebouncedInput';
import { FC } from 'react';

type NeedleTypesFiltersProps = {
  header: Header<any, unknown>;
};

const NeedleTypesFilters: FC<NeedleTypesFiltersProps> = ({ header }) => {
  return (
    <>
      {['name'].includes(header.column.id) && (
        <DebouncedInput
          value={(header.column.getFilterValue() ?? '') as string}
          type="text"
          onChange={(value) => {
            header.column.setFilterValue(value);
          }}
          className="form-control form-control-sm rounded"
          data-testid={`filter_input_${header.column.id}`}
        />
      )}
    </>
  );
};

export default NeedleTypesFilters;
