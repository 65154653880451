import { ColumnFilter, ColumnSort } from '@tanstack/react-table';
import axiosInstance from 'config/axios';
import { FluidType } from 'interfaces/equipment/fluid-type';
import { PaginationResponse } from 'interfaces/pagination-response';

const BASE_URL = '/v1/equipment/fluid-types';

export type FluidTypeResponse = PaginationResponse<FluidType>;

const FluidTypeService = {
  get: (queryParams: {
    pageIndex: number;
    pageSize: number;
    sorting?: ColumnSort[];
    columnFilters: ColumnFilter[];
  }) => {
    return axiosInstance.get<FluidTypeResponse>(BASE_URL, {
      params: queryParams,
    });
  },

  update: (id: string | number, data: Partial<FluidType>) => {
    return axiosInstance.patch<FluidType>(`${BASE_URL}/${id}`, data);
  },

  create: (data: Partial<FluidType>) => {
    return axiosInstance.post<FluidType>(BASE_URL, data);
  },

  find: (id: string | number) => {
    return axiosInstance.get<FluidType>(`${BASE_URL}/${id}`);
  },
};

export default FluidTypeService;
