import ContentHeader from 'components/UI/ContentHeader';
import GroupBox from 'components/UI/GroupBox/GroupBox';
import { SideTab, SideTabs } from 'components/UI/GroupBox/SideTabs';
import SimpleTable from 'components/UI/SimpleTable';
import { GROUPS } from '../mocks/lab-monitor';
import DropdownButton from 'components/UI/DropdownButton';
import AddSchedule from '../LabMonitor/AddSchedule';
import { useState } from 'react';

const SCHEDULE_ACTIONS = [
  {
    label: 'Add Schedule',
    value: 'add_schedule',
  },
  {
    label: 'Resume Paused',
    value: 'resume_paused',
  },
  {
    label: 'Pause Scheduled',
    value: 'pause_scheduled',
  },
  {
    label: 'Cancel Scheduled',
    value: 'cancel_scheduled',
  },
  {
    label: 'HALT',
    value: 'halt',
  },
];

const LabMonitor = () => {
  const [activeScheduleForm, setActiveScheduleForm] = useState<{ [key: number]: boolean }>({});

  const handleScheduleAction = (groupId: number, value: number | string) => {
    switch (value) {
      case 'add_schedule':
        setActiveScheduleForm({
          ...activeScheduleForm,
          [groupId]: true,
        });
        break;
      default:
    }
  };

  const handleScheduleFormCancel = (groupId: number) => {
    setActiveScheduleForm({
      ...activeScheduleForm,
      [groupId]: false,
    });
  };

  return (
    <div>
      <ContentHeader title="Lab Monitor" />
      <div>
        {GROUPS.map((group) => (
          <GroupBox key={group.id} title={group.title} summary={group.summary}>
            <SideTabs>
              <SideTab title={group.tabs.status.title}>
                <SimpleTable headers={group.tabs.status.content.headers} rows={group.tabs.status.content.rows} />
              </SideTab>
              <SideTab title={group.tabs.schedule.title}>
                {!activeScheduleForm[group.id] ? (
                  <div className="d-flex justify-content-end">
                    <DropdownButton
                      label="Group Actions"
                      onItemSelect={(value) => handleScheduleAction(group.id, value)}
                      actions={SCHEDULE_ACTIONS}
                    />
                  </div>
                ) : null}

                {!activeScheduleForm[group.id] ? (
                  <SimpleTable headers={group.tabs.schedule.content.headers} rows={group.tabs.schedule.content.rows} />
                ) : null}
                {activeScheduleForm[group.id] ? (
                  <AddSchedule onFormCancel={() => handleScheduleFormCancel(group.id)} />
                ) : null}
              </SideTab>
              <SideTab title={group.tabs.software.title}>{group.tabs.software.content}</SideTab>
              <SideTab title={group.tabs.configure.title}>{group.tabs.configure.content}</SideTab>
            </SideTabs>
          </GroupBox>
        ))}
      </div>
    </div>
  );
};

export default LabMonitor;
