import { configureStore } from '@reduxjs/toolkit';
import authReducer from 'store/slices/auth';
import overlayReducer from 'store/slices/overlay';

const store = configureStore({
  reducer: {
    auth: authReducer,
    overlay: overlayReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
