import { Link, useNavigate, useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import Button from 'components/UI/Button';
import Spinner from 'components/UI/Spinner';
import { useQueryClient } from '@tanstack/react-query';
import { ReactQueryKeys } from 'constants/react-query-keys';
import { useDispatch } from 'react-redux';
import { overlayActions } from 'store/slices/overlay';
import { parseErrorMessage } from 'helpers/parse-error-message';
import NeedleTypeService from 'services/NeedleTypeService';
import DecimalInput from 'components/UI/DecimalInput';
import { useEffect } from 'react';

const BACK_URL = '/configure-equipment/needle-types';

type FormData = {
  name: string;
  description: string | null;
  gauge_awg: number | null;
  length_in: number | null;
};

const NeedleTypesModalForm = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    dispatch(overlayActions.open({ path: BACK_URL }));
  }, []);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isLoading, isSubmitting },
  } = useForm<FormData>({
    mode: 'all',
    defaultValues: async () => {
      const defaultData: FormData = {
        name: '',
        description: null,
        gauge_awg: null,
        length_in: null,
      };

      if (params.id) {
        try {
          const { data } = await NeedleTypeService.find(params.id);
          return { ...defaultData, ...data };
        } catch (error) {
          toast.error('The needle type could not be loaded.');
          navigate(BACK_URL);
          return defaultData;
        }
      } else {
        return defaultData;
      }
    },
  });

  /**
   * Submit form and create user
   *
   * @param formData User data
   */
  const handleSubmitData = async (formData: FormData) => {
    const data = {
      ...formData,
      name: formData.name.trim(),
      description: formData.description === '' || formData.description === null ? null : formData.description?.trim(),
      gauge_awg: Number(formData.gauge_awg),
      length_in: Number(formData.length_in),
    };

    if (params.id) {
      try {
        await NeedleTypeService.update(params.id, data);
        queryClient.invalidateQueries({
          queryKey: [ReactQueryKeys.EQUIPMENT_NEEDLE_TYPES],
        });
        toast.success('The needle type was successfully updated!');
        navigate(BACK_URL);
      } catch (e: any) {
        const message = 'The needle type could not be updated. Please try again.';
        // if (e?.response?.data?.statusCode === 400 || e?.response?.data?.statusCode === 502) {
        //   message = parseErrorMessage(e.response.data);
        // }
        toast.error(message);
      }
    } else {
      try {
        await NeedleTypeService.create(data);
        queryClient.invalidateQueries({
          queryKey: [ReactQueryKeys.EQUIPMENT_NEEDLE_TYPES],
        });
        toast.success('The needle type was successfully created!');
        navigate(BACK_URL);
      } catch (e: any) {
        const message = 'The needle type could not be created. Please try again.';
        // if (e.response?.data?.statusCode === 400 || e?.response?.data?.statusCode === 502) {
        //   message = parseErrorMessage(e.response.data);
        // }
        toast.error(message);
      }
    }
  };

  // onKeyUp handler function
  const keyUpHandler = (event: any) => {
    if (event.code === 'Escape') {
      navigate(BACK_URL);
    }
  };

  return (
    <form
      id="formid"
      autoComplete="off"
      data-testid="form"
      onKeyUp={keyUpHandler}
      onSubmit={handleSubmit((data) => handleSubmitData(data))}
    >
      <div className={'modal-sidebar-body pb-4 ' + (isLoading || isSubmitting ? 'opacity-50' : '')}>
        {isLoading && <Spinner isAbsolute />}

        <div className="row">
          {/* Name */}
          <div className="col-12">
            <label htmlFor="name" className="form-label">
              Needle Name *
            </label>
            <input
              type="text"
              autoFocus
              className="form-control form-control-lg rounded"
              id="name"
              data-testid="name"
              {...register('name', {
                required: true,
                maxLength: 24,
                minLength: 3,
              })}
            />
            {errors?.name?.type === 'required' && <div className="invalid-feedback pt-1">Needle name is required</div>}
            {errors?.name?.type === 'maxLength' && (
              <div className="invalid-feedback pt-1">Needle name must have maximum 24 characters</div>
            )}
            {errors?.name?.type === 'minLength' && (
              <div className="invalid-feedback pt-1">Needle name must have minimum 3 characters</div>
            )}
          </div>
        </div>

        <div className="row pt-4">
          {/* Needle Gauge */}
          <div className="col-6">
            <label htmlFor="gauge_awg" className="form-label">
              Needle Gauge *
            </label>
            <Controller
              control={control}
              name="gauge_awg"
              rules={{
                required: true,
                min: 1,
                max: 36,
              }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <DecimalInput
                  className={'form-control form-control-lg rounded'}
                  id="gauge_awg"
                  data-testid="gauge_awg"
                  value={String(value)}
                  onChange={onChange}
                  onBlur={onBlur}
                  precision={0}
                  ref={ref}
                  alignCenter={false}
                  inputGroup={true}
                  inputGroupText="AWG"
                />
              )}
            />
            {errors?.gauge_awg?.type === 'required' && (
              <div className="invalid-feedback pt-1">Needle Gauge is required</div>
            )}
            {errors?.gauge_awg?.type === 'min' || errors?.gauge_awg?.type === 'max' ? (
              <div className="invalid-feedback pt-1"> Needle Gauge value should be between 1 and 36</div>
            ) : null}
          </div>

          {/* Length (inches) */}
          <div className="col-6">
            <label htmlFor="length_in" className="form-label">
              Length (inches) *
            </label>
            <Controller
              control={control}
              name="length_in"
              rules={{
                required: true,
                min: 0.01,
                max: 9.99,
              }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <DecimalInput
                  className={'form-control form-control-lg rounded'}
                  id="length_in"
                  data-testid="length_in"
                  value={String(value)}
                  onChange={onChange}
                  onBlur={onBlur}
                  precision={2}
                  ref={ref}
                  alignCenter={false}
                  inputGroup={true}
                  inputGroupText="in"
                />
              )}
            />
            {errors?.length_in?.type === 'required' && <div className="invalid-feedback pt-1">Lenght is required</div>}
            {errors?.length_in?.type === 'min' || errors?.length_in?.type === 'max' ? (
              <div className="invalid-feedback pt-1">Length value should be between 0.01 and 9.99</div>
            ) : null}
          </div>
        </div>

        <div className="row pt-4">
          {/* Description */}
          <div className="col-12">
            <label htmlFor="description" className="form-label">
              Note
            </label>
            <textarea
              rows={1}
              id="description"
              className="form-control form-control-lg rounded"
              data-testid="description"
              {...register('description', {
                required: false,
                maxLength: 128,
              })}
            ></textarea>
            {errors?.description?.type === 'maxLength' && (
              <div className="invalid-feedback pt-1">Note must have maximum 128 characters</div>
            )}
          </div>
        </div>
      </div>

      <div className="modal-sidebar-footer">
        <Button
          loading={isSubmitting}
          defaultLabel="Save"
          loadingLabel={params.id ? 'Updating' : 'Saving'}
          type="submit"
          disabled={isSubmitting || isLoading}
        ></Button>

        <Link
          to={BACK_URL}
          className={`btn btn-lg rounded btn-secondary ${isSubmitting || isLoading ? 'disabled' : ''}`}
        >
          Cancel
        </Link>
      </div>
    </form>
  );
};

export default NeedleTypesModalForm;
