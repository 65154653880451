import { FC } from 'react';

interface AddScheduleProps {
  onFormCancel: () => void;
}

const AddSchedule: FC<AddScheduleProps> = ({ onFormCancel }) => {
  const handleCancel = (e: React.MouseEvent) => {
    e.preventDefault();
    onFormCancel();
  };

  return (
    <div className="card bg-primary">
      <div className="card-header bg-primary">
        <h5 className="card-title mb-0">Add Schedule</h5>
      </div>
      <div className="card-body bg-dark">
        <form>
          <div className="row mb-3 align-items-center">
            <div className="col-md-2">
              <label htmlFor="testProtocol" className="form-label">
                Test Protocol
              </label>
              <select id="testProtocol" className="form-select">
                <option value="TIR-101">TIR-101</option>
              </select>
            </div>
            <div className="col-md-10 d-flex align-items-center">
              <small className="text-muted d-block pt-4">
                Description: Multi-stage AAMI TIR-101 test including a warm up period, Tb accuracy test, accelerated
                delivery period, and final Te accuracy test.
              </small>
            </div>
          </div>
          <h5 className="mt-5 mb-3">
            Enter Parameters for Protocol: <span id="protocolName">TIR-101</span>
          </h5>
          <div className="row mb-3">
            <div className="col-md-2">
              <label htmlFor="t0DeliveryRate" className="form-label">
                T0 Delivery Rate *
              </label>
              <div className="input-group">
                <input type="number" className="form-control" id="t0DeliveryRate" placeholder="10" />
                <span className="input-group-text">mL/Hr</span>
              </div>
            </div>
            <div className="col-md-2">
              <label htmlFor="tbDeliveryRate" className="form-label">
                Tb Delivery Rate *
              </label>
              <div className="input-group">
                <input type="number" className="form-control" id="tbDeliveryRate" placeholder="10" />
                <span className="input-group-text">mL/Hr</span>
              </div>
            </div>
            <div className="col-md-2">
              <label htmlFor="acceleratedDeliveryRate" className="form-label">
                Accelerated Delivery Rate *
              </label>
              <div className="input-group">
                <input type="number" className="form-control" id="acceleratedDeliveryRate" placeholder="1000" />
                <span className="input-group-text">mL/Hr</span>
              </div>
            </div>
            <div className="col-md-2">
              <label htmlFor="teDeliveryRate" className="form-label">
                Te Delivery Rate *
              </label>
              <div className="input-group">
                <input type="number" className="form-control" id="teDeliveryRate" placeholder="10" />
                <span className="input-group-text">mL/Hr</span>
              </div>
            </div>
            <div className="col-md-2">
              <label htmlFor="numTests" className="form-label">
                # Tests *
              </label>
              <input type="number" className="form-control" id="numTests" placeholder="10" />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-2">
              <label htmlFor="t0Time" className="form-label">
                T0 Time *
              </label>
              <div className="input-group">
                <input type="number" className="form-control" id="t0Time" placeholder="60" />
                <span className="input-group-text">Min</span>
              </div>
            </div>
            <div className="col-md-2">
              <label htmlFor="tbDeliveryTime" className="form-label">
                Tb Delivery Time *
              </label>
              <div className="input-group">
                <input type="number" className="form-control" id="tbDeliveryTime" placeholder="60" />
                <span className="input-group-text">Min</span>
              </div>
            </div>
            <div className="col-md-2">
              <label htmlFor="acceleratedDeliveryTime" className="form-label">
                Accelerated Delivery Time *
              </label>
              <div className="input-group">
                <input type="number" className="form-control" id="acceleratedDeliveryTime" placeholder="1" />
                <span className="input-group-text">Hr</span>
              </div>
            </div>
            <div className="col-md-2">
              <label htmlFor="teDeliveryTime" className="form-label">
                Te Delivery Time *
              </label>
              <div className="input-group">
                <input type="number" className="form-control" id="teDeliveryTime" placeholder="60" />
                <span className="input-group-text">Min</span>
              </div>
            </div>
            <div className="col-md-4">
              <label htmlFor="testNote" className="form-label">
                Test Note
              </label>
              <input type="text" className="form-control" id="testNote" />
            </div>
          </div>
          <div className="card bg-primary mt-4">
            <div className="card-header bg-primary">
              <h5 className="card-title mb-0">Review Test Parameters</h5>
            </div>
            <div className="card-body bg-dark text-white">
              <div className="row">
                <div className="col-md-3">
                  <strong>Test Protocol:</strong> TIR-101
                </div>
                <div className="col-md-3">
                  <strong>Total VTBI:</strong> 10,030 mL (30mL to balance)
                </div>
                <div className="col-md-3">
                  <strong>Total Runtime:</strong> 40 Hr
                </div>
                <div className="col-md-3">
                  <strong>Est. Completion Time:</strong> 2024-10-07 10:00 AM
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end mt-4">
            <button type="button" className="btn btn-primary me-2">
              Schedule Tests
            </button>
            <a href="#" className="btn btn-secondary" onClick={handleCancel}>
              Cancel / Clear
            </a>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddSchedule;
