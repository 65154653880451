export type TableHeader = {
  key: string;
  label: string;
};

export type TableRow = {
  id: number | string;
  [key: string]: string | number | boolean | null; // Adjust types based on the possible data types for your table rows
};

export type TableData = {
  headers: TableHeader[];
  rows: TableRow[];
};

const SimpleTable = (tableData: TableData) => {
  return (
    <table className="table table-dark">
      <thead>
        <tr>
          {tableData.headers.map((header) => (
            <th key={header.key}>{header.label}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {tableData.rows.map((row) => (
          <tr key={row.id}>
            {tableData.headers.map((header) => (
              <td key={header.key}>{row[header.key]}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default SimpleTable;
