import ContentHeader from 'components/UI/ContentHeader';
import { Outlet } from 'react-router-dom';

const ConfigureEquipment = () => {
  return (
    <div>
      <ContentHeader title="Configure Equipment" />
      <Outlet />
    </div>
  );
};

export default ConfigureEquipment;
