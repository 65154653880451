import ContentHeader from 'components/UI/ContentHeader';
import { Outlet } from 'react-router-dom';

export const SETTINGS_LINKS = [
  {
    title: 'Users',
    url: '/settings/users',
  },
];

const Settings = () => {
  return (
    <>
      <ContentHeader links={SETTINGS_LINKS} title="Settings" />
      <div className="main-container with-tabs">
        <Outlet />
      </div>
    </>
  );
};

export default Settings;
