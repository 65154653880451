import Button from 'components/UI/Button';
import logo from 'assets/images/logo.svg';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from 'config/msal';

const Login = () => {
  const { instance } = useMsal();

  const handleLogin = () => {
    instance.loginRedirect(loginRequest);
  };

  return (
    <div className="login-page d-flex flex-column justify-content-center align-items-center">
      <div className="login-page__logo-wrapper">
        <img src={logo} alt="Logo" />
      </div>

      <Button type="button" defaultLabel="Sign in with Single Sign-on" clicked={handleLogin} />
    </div>
  );
};

export default Login;
