import { ColumnFilter, ColumnSort } from '@tanstack/react-table';
import axiosInstance from 'config/axios';
import { PaginationResponse } from 'interfaces/pagination-response';
import { Role } from 'interfaces/role';
import { User } from 'interfaces/user';

const BASE_URL = '/v1/users';

export type UserResponse = PaginationResponse<User>;

const UserService = {
  get: (queryParams: {
    pageIndex: number;
    pageSize: number;
    sorting?: ColumnSort[];
    columnFilters: ColumnFilter[];
  }) => {
    return axiosInstance.get<UserResponse>(BASE_URL, {
      params: queryParams,
    });
  },

  update: (id: string | number, data: Partial<User>) => {
    return axiosInstance.patch<User>(`${BASE_URL}/${id}`, data);
  },

  find: (id: string | number) => {
    return axiosInstance.get<User>(`${BASE_URL}/${id}`);
  },

  getRoles: () => {
    return axiosInstance.get<Role[]>(`${BASE_URL}/roles`);
  },

  authenticate: () => {
    return axiosInstance.post<User>(`${BASE_URL}/auth`);
  },
};

export default UserService;
