import { ColumnFilter, ColumnSort } from '@tanstack/react-table';
import axiosInstance from 'config/axios';
import { NeedleType } from 'interfaces/equipment/needle-type';
import { PaginationResponse } from 'interfaces/pagination-response';

const BASE_URL = '/v1/equipment/needle-types';

export type NeedleTypeResponse = PaginationResponse<NeedleType>;

const NeedleTypeService = {
  get: (queryParams: {
    pageIndex: number;
    pageSize: number;
    sorting?: ColumnSort[];
    columnFilters: ColumnFilter[];
  }) => {
    return axiosInstance.get<NeedleTypeResponse>(BASE_URL, {
      params: queryParams,
    });
  },

  update: (id: string | number, data: Partial<NeedleType>) => {
    return axiosInstance.patch<NeedleType>(`${BASE_URL}/${id}`, data);
  },

  create: (data: Partial<NeedleType>) => {
    return axiosInstance.post<NeedleType>(BASE_URL, data);
  },

  find: (id: string | number) => {
    return axiosInstance.get<NeedleType>(`${BASE_URL}/${id}`);
  },

  delete: (id: string | number) => {
    return axiosInstance.delete<NeedleType>(`${BASE_URL}/${id}`);
  },
};

export default NeedleTypeService;
