import { Link, useNavigate, useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { toast } from 'react-hot-toast';
import Button from 'components/UI/Button';
import Spinner from 'components/UI/Spinner';
import { useQueryClient } from '@tanstack/react-query';
import { ReactQueryKeys } from 'constants/react-query-keys';
import { useDispatch } from 'react-redux';
import { overlayActions } from 'store/slices/overlay';
import UserService from 'services/UserService';
import { User } from 'interfaces/user';
import Select from 'react-select';
import { parseErrorMessage } from 'helpers/parse-error-message';
import { Role } from 'interfaces/role';

type FormData = {
  id?: number;
  name: string;
  email: string;
  role: {
    value: string;
    label: string;
  };
};

const UsersModalForm = () => {
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<FormData>({
    mode: 'all',
  });

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const params = useParams();
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [_, setEditing] = useState(false);
  const [roles, setRoles] = useState<Role[]>([]);

  /**
   * Submit form and create user
   *
   * @param formData User data
   */
  const handleSubmitData = async (formData: FormData) => {
    const data = {
      name: formData.name.trim(),
      role_id: formData.role?.value ? +formData.role?.value : null,
    };
    setFormSubmitting(true);

    if (params.id) {
      try {
        await UserService.update(params.id, data);
        queryClient.invalidateQueries({
          queryKey: [ReactQueryKeys.USERS],
        });
        toast.success('The user was successfully updated!');
        navigate('/settings/users');
      } catch (e: any) {
        let message = 'The user could not be updated. Please try again.';
        if (e.response?.data?.statusCode === 400 || e?.response?.data?.statusCode === 502) {
          message = parseErrorMessage(e.response.data);
        }
        toast.error(message);
      }
    }

    setFormSubmitting(false);
  };

  // onKeyUp handler function
  const keyUpHandler = (event: any) => {
    if (event.code === 'Escape') {
      navigate('/settings/users');
    }
  };

  /**
   * Get companies for the company dropdown
   */
  const getRoles = async () => {
    try {
      const { data: roles } = await UserService.getRoles();

      setRoles(roles);
      return roles;
    } catch (e) {
      toast.error('The roles could not be loaded. Please try again.');
      return Promise.reject();
    }
  };

  useEffect(() => {
    dispatch(overlayActions.open({ path: '/settings/users' }));
    getRoles()
      .then((roles) => {
        if (params.id) {
          setFormLoading(true);
          UserService.find(params.id)
            .then(({ data }: { data: User }) => {
              setValue('name', data.name);
              setValue('email', data.email);
              if (data.role_id) {
                const role: Role | undefined = roles?.find((r: Role) => String(r.id) === String(data.role_id));
                if (role) {
                  setValue('role', {
                    label: role?.name,
                    value: String(role?.id),
                  });
                }
              }

              setFormLoading(false);
              setEditing(true);
            })
            .catch(() => {
              toast.error('The user could not be loaded.');
              navigate('/settings/users');
            });
        }
      })
      .catch(() => {
        toast.error('The roles can not be loaded.');
      });
  }, []);

  return (
    <form
      id="formid"
      autoComplete="off"
      data-testid="form"
      onKeyUp={keyUpHandler}
      onSubmit={handleSubmit((data) => handleSubmitData(data))}
    >
      <div className={'modal-sidebar-body pb-4 ' + (formLoading || formSubmitting ? 'opacity-50' : '')}>
        {formLoading && <Spinner isAbsolute />}

        <div className="row">
          {/* Name */}
          <div className="col-12">
            <label htmlFor="name" className="form-label">
              Name *
            </label>
            <input
              type="text"
              autoFocus
              className="form-control form-control-lg rounded"
              id="name"
              data-testid="name"
              {...register('name', {
                required: true,
              })}
            />
            {errors?.name?.type === 'required' && <div className="invalid-feedback pt-1">Name is required</div>}
          </div>
        </div>

        <div className="row pt-4">
          {/* Email */}
          <div className="col-12">
            <label htmlFor="email" className="form-label">
              Email
            </label>
            <input
              type="text"
              className="form-control form-control-lg rounded form-control--transparent"
              id="email"
              data-testid="email"
              readOnly
              {...register('email', {
                required: true,
              })}
            />
          </div>
        </div>

        <div className="row pt-4">
          {/* Access Level */}
          <div className="col-12">
            <label htmlFor="accessLevel" className="form-label">
              Access Level
            </label>
            <Controller
              control={control}
              name="role"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Select
                  id="accessLevel"
                  maxMenuHeight={300}
                  ref={ref}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value as any}
                  isMulti={false}
                  aria-label="access_level"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  isClearable
                  isSearchable
                  options={roles?.map((role: Role) => ({
                    label: role.name,
                    value: role.id,
                  }))}
                />
              )}
            />
          </div>
        </div>
      </div>

      <div className="modal-sidebar-footer">
        <Button
          loading={formSubmitting}
          defaultLabel="Save"
          loadingLabel={params.id ? 'Updating' : 'Saving'}
          type="submit"
          disabled={formSubmitting || formLoading}
        ></Button>

        <Link
          to="/settings/users"
          className={`btn btn-lg rounded btn-secondary ${formSubmitting || formLoading ? 'disabled' : ''}`}
        >
          Cancel
        </Link>
      </div>
    </form>
  );
};

export default UsersModalForm;
