import type { FC, MouseEventHandler } from 'react';
import { Link } from 'react-router-dom';
import { IoMdCreate, IoMdTrash, IoIosEyeOff } from 'react-icons/io';

interface TableActionsProps {
  onDelete?: MouseEventHandler<HTMLButtonElement> | undefined | null;
  onDisable?: MouseEventHandler<HTMLButtonElement> | undefined | null;
  editLink: string;
  buttons?: JSX.Element;
}

const TableActions: FC<TableActionsProps> = ({ editLink, onDelete, onDisable, buttons = null }) => {
  return (
    <div className="table-actions">
      {!!buttons && buttons}
      {!!editLink && (
        <Link className="table-actions-edit" data-testid="table-actions-edit" to={editLink}>
          <span>
            <IoMdCreate style={{ color: '#fff' }} size={18} />
          </span>
        </Link>
      )}
      {!!onDelete && (
        <button className="table-actions-delete" data-testid="table-actions-delete" onClick={onDelete}>
          <span>
            <IoMdTrash style={{ color: '#fff', width: 'auto' }} size={20} />
          </span>
        </button>
      )}
      {!!onDisable && (
        <button className="table-actions-disable" data-testid="table-actions-disable" onClick={onDisable}>
          <span>
            <IoIosEyeOff style={{ color: '#fff', width: 'auto' }} size={20} />
          </span>
        </button>
      )}
    </div>
  );
};
export default TableActions;
