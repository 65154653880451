import type { FC, MouseEventHandler } from 'react';

type ButtonProps = {
  type?: 'button' | 'submit';
  defaultLabel: string;
  loadingLabel?: string;
  loading?: boolean;
  disabled?: boolean;
  clicked?: MouseEventHandler<HTMLButtonElement>;
};

const Button: FC<ButtonProps> = ({
  defaultLabel,
  loadingLabel,
  clicked,
  loading = false,
  disabled = false,
  type = 'button',
  ...rest
}) => {
  return (
    <button onClick={clicked} className="btn btn-lg rounded btn-primary me-3" disabled={disabled} type={type} {...rest}>
      {loading && <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>}
      {loading ? loadingLabel : defaultLabel}
    </button>
  );
};
export default Button;
