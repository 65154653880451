import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div className="not-found-page">
      <div className="text-center">
        <h1>404 Not Found</h1>
        <Link to={'/'}>Go Back</Link>
      </div>
    </div>
  );
};

export default NotFound;
