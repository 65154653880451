import React, { useCallback, useMemo, useState } from 'react';
import { keepPreviousData, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  getCoreRowModel,
  useReactTable,
  PaginationState,
  ColumnDef,
  getSortedRowModel,
  getFilteredRowModel,
  SortingState,
  ColumnFiltersState,
  Table as ReactTable,
} from '@tanstack/react-table';
import Pagination from 'components/UI/Pagination';
import Table from 'components/UI/Table';
import { ReactQueryKeys } from 'constants/react-query-keys';
import { NeedleType } from 'interfaces/equipment/needle-type';
import TableActions from 'components/UI/TableActions';
import { Link, Outlet } from 'react-router-dom';
import { EQUIPMENT_LINKS } from 'constants/equipment-links';
import { formatDateLong } from 'helpers/date-helpers';
import { SideTabsLinks } from 'components/UI/GroupBox/SideTabsLinks';
import GroupBox from 'components/UI/GroupBox/GroupBox';
import { IoIosAdd } from 'react-icons/io';
import NeedleTypeService from 'services/NeedleTypeService';
import NeedleTypesFilters from './NeedleTypesFilters';
import SwalAlert, { firePreConfirmAlert } from 'components/UI/SwalAlert';
import toast from 'react-hot-toast';
import { SweetAlertResult } from 'sweetalert2';

const NeedleTypes = () => {
  const queryClient = useQueryClient();
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 50,
  });
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const dataQuery = useQuery({
    queryKey: [ReactQueryKeys.EQUIPMENT_NEEDLE_TYPES, { pageIndex, pageSize, sorting, columnFilters }],
    queryFn: () =>
      NeedleTypeService.get({
        pageIndex,
        pageSize,
        sorting,
        columnFilters,
      }),
    placeholderData: keepPreviousData,
  });

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const handleTableDeleteShow = (needleType: NeedleType) => {
    firePreConfirmAlert({
      title: 'Delete Needle Type',
      html: `Are you sure you want to delete needle type <strong>${needleType.name}</strong>?`,
      preConfirm: () => {
        return NeedleTypeService.delete(needleType.id)
          .then(({ data }) => {
            return data;
          })
          .catch((error) => {
            SwalAlert.showValidationMessage(error?.response?.data?.message);
            //return false to prevent pop up from closing when running tests, check preconfirm fn
            return false;
          });
      },
    }).then((data: SweetAlertResult) => {
      if (data.isConfirmed) {
        queryClient.invalidateQueries({
          queryKey: [ReactQueryKeys.EQUIPMENT_NEEDLE_TYPES],
        });
        toast.success('The needle type was successfully deleted!');
      }
    });
  };

  const handleMemoizedTableDeleteShow = useCallback(handleTableDeleteShow, [queryClient]);

  /**
   * Define columns for the table
   */
  const columns = useMemo<ColumnDef<NeedleType>[]>(
    () => [
      {
        id: 'name',
        accessorKey: 'name',
        header: 'Name',
        cell: ({ row }) => <div data-testid={`name_${row?.original?.id}`}>{`${row.original.name}`}</div>,
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        id: 'gauge_awg',
        accessorKey: 'gauge_awg',
        header: 'Gauge (awg)',
        className: 'text-end',
        enableSorting: false,
        enableColumnFilter: false,
        cell: ({ row }) => <div className="text-right">{row?.original?.gauge_awg + ' AWG'}</div>,
      },
      {
        id: 'length_in',
        accessorKey: 'length_in',
        className: 'text-end',
        header: 'Length (in)',
        enableSorting: false,
        enableColumnFilter: false,
        cell: ({ row }) => <div className="text-right">{row?.original?.length_in + ' in'}</div>,
      },
      {
        id: 'description',
        accessorKey: 'description',
        header: 'Note',
        cell: ({ row }) => (
          <div data-testid={`description_${row?.original?.id}`}>{`${row?.original?.description ?? 'N/A'}`}</div>
        ),
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        id: 'updated_by_user',
        accessorKey: 'updated_by_user',
        header: 'Updated by',
        enableSorting: false,
        enableColumnFilter: false,
        cell: ({ row }) => <div>{row?.original?.updated_by_user?.name || 'N/A'}</div>,
      },
      {
        id: 'updated_at',
        accessorKey: 'updated_at',
        header: 'Update date',
        enableSorting: false,
        enableColumnFilter: false,
        cell: ({ row }) => (
          <div className="table-actions-wrapper">
            <div>{formatDateLong(row?.original?.updated_at)}</div>
            <TableActions
              editLink={`/configure-equipment/needle-types/${row.original.id}`}
              // onDelete={() => {
              //   handleMemoizedTableDeleteShow(row.original);
              // }}
            />
          </div>
        ),
      },
    ],
    [handleMemoizedTableDeleteShow]
  );

  /**
   * Define react table
   */
  const table: ReactTable<NeedleType> = useReactTable({
    data: dataQuery?.data?.data.records ?? [],
    columns,
    pageCount: dataQuery?.data?.data.totalPages ?? -1,
    state: {
      pagination,
      sorting,
      columnFilters,
    },
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    manualPagination: true,
    manualSorting: true,
    manualFiltering: true,
    enableMultiSort: false,
  });

  return (
    <>
      <div>
        <GroupBox
          title={'CONFIG: Needle Types'}
          addBtn={
            <Link to="/configure-equipment/needle-types/add" className="btn-rounded" data-testid="add-btn">
              <IoIosAdd size={40} />
            </Link>
          }
          summary={[
            {
              title: 'NEEDLE TYPES',
              value: dataQuery?.data?.data.total ?? 0,
              classes: '',
            },
          ]}
        >
          <div className="d-flex flex-row">
            <SideTabsLinks links={EQUIPMENT_LINKS} />
            <div className="flex-grow-1 side-tabs__content">
              <Table
                classes="react-table"
                isFetching={dataQuery.isFetching}
                table={table}
                filters={(header) => <NeedleTypesFilters header={header} />}
              />
              <Pagination table={table} />
            </div>
          </div>
        </GroupBox>
      </div>
      <Outlet />
    </>
  );
};

export default NeedleTypes;
