import { Outlet } from 'react-router-dom';
import { useIsAuthenticated, AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { useEffect } from 'react';
import UserService from 'services/UserService';
import Header from './Header/Header';
import classNames from 'classnames';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { authActions, selectUser } from 'store/slices/auth';
import Spinner from 'components/UI/Spinner';
import Login from 'pages/Login';
import Overlay from 'components/UI/Overlay';

const AdminLayout = () => {
  const dispatch = useAppDispatch();
  const isAuthenticated = useIsAuthenticated();
  const user = useAppSelector(selectUser);
  const isOverlayOpen: boolean = useAppSelector((state) => state.overlay.isOpen);

  useEffect(() => {
    if (!isAuthenticated) return;

    UserService.authenticate().then((response) => {
      if (response && response.data) {
        dispatch(
          authActions.updateUser({
            user: response.data,
          })
        );
      }
    });
  }, [isAuthenticated]);

  const mainContainerClasses = classNames({
    'page-container': true,
  });

  return (
    <main>
      <AuthenticatedTemplate>
        {user ? (
          <>
            <Header />
            <main className={mainContainerClasses}>
              {isOverlayOpen && <Overlay />}
              <Outlet />
            </main>
          </>
        ) : (
          <Spinner isAbsolute={true} />
        )}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Login />
      </UnauthenticatedTemplate>
    </main>
  );
};

export default AdminLayout;
