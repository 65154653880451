import React from 'react';
import { Tooltip } from 'react-tooltip';

interface ReactTooltipProps extends React.ComponentProps<typeof Tooltip> {
  children?: React.ReactNode;
}

const ReactTooltip: React.FC<ReactTooltipProps> = ({ children, style, ...props }) => {
  return (
    <Tooltip {...props} style={{ ...style, zIndex: 1001, fontSize: '13px' }}>
      {children}
    </Tooltip>
  );
};

export default ReactTooltip;
