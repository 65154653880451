import { useState, useMemo, useEffect } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';
import {
  getCoreRowModel,
  useReactTable,
  PaginationState,
  ColumnDef,
  getSortedRowModel,
  getFilteredRowModel,
  SortingState,
  ColumnFiltersState,
  Table as ReactTable,
} from '@tanstack/react-table';
import TableActions from 'components/UI/TableActions';
import UserService from 'services/UserService';
import { ReactQueryKeys } from 'constants/react-query-keys';
import Pagination from 'components/UI/Pagination';
// import DebouncedInput from '../../components/UI/DebouncedInput/DebouncedInput';
import { User } from 'interfaces/user';
import Select from 'react-select';
import Table from 'components/UI/Table';
import DebouncedInput from 'components/UI/DebouncedInput';
import { Role } from 'interfaces/role';

const Users = () => {
  const [roles, setRoles] = useState<Role[]>([]);

  /**
   * Get roles for the filter
   */
  const getRoles = async () => {
    try {
      const { data: roles } = await UserService.getRoles();

      setRoles(roles);
    } catch (_) {
      toast.error('The roles could not be loaded. Please try again.');
    }
  };

  useEffect(() => {
    getRoles();
  }, []);

  /**
   * Define columns for the table
   */
  const columns = useMemo<ColumnDef<User>[]>(
    () => [
      {
        id: 'name',
        accessorKey: 'name',
        header: 'Name',
        cell: ({ row }) => (
          <div data-testid={`first_name_${row.original.entra_id}`}>
            {' '}
            <Link
              data-testid={`entra_id_${row.original.entra_id}`}
              to={`/settings/users/${row.original.id}`}
            >{`${row.original.name}`}</Link>
          </div>
        ),
        enableSorting: true,
        enableColumnFilter: true,
      },
      {
        id: 'entra_id',
        accessorKey: 'entra_id',
        header: 'Entra ID',
        enableSorting: true,
        enableColumnFilter: true,
        cell: ({ row }) => row.original.entra_id,
      },
      {
        id: 'email',
        accessorKey: 'email',
        header: 'Email',
        cell: ({ row }) => <div data-testid={`email_${row.original.entra_id}`}>{`${row.original.email}`}</div>,
        enableSorting: true,
        enableColumnFilter: true,
      },
      {
        id: 'role',
        accessorKey: 'role_name',
        header: 'Role',
        cell: ({ row }) => (
          <div className="table-actions-wrapper">
            <div data-testid={`role_${row.original.entra_id}`}>{row.original?.role_name || 'N/A'}</div>
            <TableActions editLink={`/settings/users/${row.original.id}`} />
          </div>
        ),
        enableSorting: true,
        enableColumnFilter: true,
      },
    ],
    []
  );

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 50,
  });
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const dataQuery = useQuery({
    queryKey: [ReactQueryKeys.USERS, { pageIndex, pageSize, sorting, columnFilters }],
    queryFn: () =>
      UserService.get({
        pageIndex,
        pageSize,
        sorting,
        columnFilters,
      }),
    placeholderData: keepPreviousData,
  });

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  /**
   * Define react table
   */
  const table: ReactTable<User> = useReactTable({
    data: dataQuery.data?.data.records ?? [],
    columns,
    pageCount: dataQuery?.data?.data.totalPages ?? -1,
    state: {
      pagination,
      sorting,
      columnFilters,
    },
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    manualPagination: true,
    manualSorting: true,
    manualFiltering: true,
    enableMultiSort: false,
  });

  return (
    <>
      <Table
        classes="react-table"
        isFetching={dataQuery.isFetching}
        table={table}
        filters={(header) => {
          return (
            <>
              {/* Role filter */}
              {header.column.id === 'role' && (
                <Select
                  id="role_id"
                  aria-label="role_id"
                  maxMenuHeight={300}
                  onChange={header.column.setFilterValue}
                  value={header.column.getFilterValue()}
                  isMulti={false}
                  className="react-select-container-sm"
                  classNamePrefix="react-select"
                  isClearable
                  isSearchable
                  options={roles?.map((role) => ({
                    label: role.name,
                    value: role.id,
                  }))}
                />
              )}

              {['name', 'entra_id', 'email'].includes(header.column.id) && (
                <DebouncedInput
                  value={(header.column.getFilterValue() ?? '') as string}
                  type="text"
                  onChange={(value) => {
                    header.column.setFilterValue(value);
                  }}
                  className="form-control form-control-sm rounded"
                  data-testid={`filter_input_${header.column.id}`}
                />
              )}
            </>
          );
        }}
      />
      <Pagination table={table} />
      <Outlet />
    </>
  );
};

export default Users;
