export const GROUPS = [
  {
    id: 1,
    title: 'Group1: 2200g Balances',
    summary: [
      {
        title: 'DEVICES',
        value: 5,
        classes: '',
      },
      {
        title: 'ACTIVE',
        value: 4,
        classes: '',
      },
      {
        title: 'IDLE',
        value: 0,
        classes: '',
      },
      {
        title: 'ERROR',
        value: 1,
        classes: 'text-danger',
      },
    ],
    tabs: {
      status: {
        title: 'STATUS',
        content: {
          headers: [
            {
              key: 'id',
              label: 'TK#',
            },
            {
              key: 'serial',
              label: 'Serial #',
            },
            {
              key: 'device_status',
              label: 'Device Status',
            },
            {
              key: 'test_queued',
              label: 'Test Queued',
            },
            {
              key: 'iv_set',
              label: 'IV Set',
            },
            {
              key: 'balance_max',
              label: 'Balance / MAX',
            },
            {
              key: 'software_loadout',
              label: 'Software Loadout',
            },
            {
              key: 'cal_date',
              label: 'Cal Date',
            },
            {
              key: 'disk_free',
              label: 'Disk Free',
            },
            {
              key: 'status_message',
              label: 'Status Message',
            },
            {
              key: 'data_age',
              label: 'Data Age',
            },
            {
              key: 'device_control',
              label: 'Device Control',
            },
          ],

          rows: [
            {
              id: '51',
              serial: '29001',
              device_status: 'DELIVERING',
              test_queued: '10 (20.0 hr)',
              iv_set: 'M6-V04-V11-S-6',
              balance_max: '1125.05 / 2000g',
              software_loadout: 'Current Main',
              cal_date: '2024/10/15',
              disk_free: '235MB',
              status_message: 'ERROR: Balance Max Threshold Hit',
              data_age: '8 sec',
              device_control: '',
            },
            {
              id: '52',
              serial: '29002',
              device_status: 'DELIVERING',
              test_queued: '10 (20.0 hr)',
              iv_set: 'M6-V04-V11-S-6',
              balance_max: '1125.05 / 2000g',
              software_loadout: 'Current Main',
              cal_date: '2024/10/15',
              disk_free: '235MB',
              status_message: 'ERROR: Balance Max Threshold Hit',
              data_age: '8 sec',
              device_control: '',
            },
            {
              id: '53',
              serial: '29003',
              device_status: 'DELIVERING',
              test_queued: '10 (20.0 hr)',
              iv_set: 'M6-V04-V11-S-6',
              balance_max: '1125.05 / 2000g',
              software_loadout: 'Current Main',
              cal_date: '2024/10/15',
              disk_free: '235MB',
              status_message: 'ERROR: Balance Max Threshold Hit',
              data_age: '8 sec',
              device_control: '',
            },
          ],
        },
      },
      schedule: {
        title: 'SCHEDULE',
        content: {
          headers: [
            {
              key: 'id',
              label: 'Schedule ID',
            },
            {
              key: 'status',
              label: 'Status',
            },
            {
              key: 'test_protocol',
              label: 'Test Protocol',
            },
            {
              key: 'tests',
              label: '# Tests',
            },
            {
              key: 'delivery_rate',
              label: 'Delivery Rate',
            },
            {
              key: 'delivery_vtbi',
              label: 'Delivery VTBI',
            },
            {
              key: 'total_vtbi',
              label: 'Total VTBI',
            },
            {
              key: 'time_remaining',
              label: 'Est. Time Remaining',
            },
            {
              key: 'schedule_control',
              label: 'Schedule Control',
            },
          ],

          rows: [
            {
              id: '5151',
              status: 'ACTIVE',
              test_protocol: 'Single Rate FRAT',
              tests: '10 (20.0 hr)',
              delivery_rate: '100 mL/hr',
              delivery_vtbi: '100 mL',
              total_vtbi: '1000 mL',
              time_remaining: '10.0 Hrs',
              schedule_control: '',
            },
            {
              id: '5155',
              status: 'ACTIVE',
              test_protocol: 'TIR-101',
              tests: '10 (20.0 hr)',
              delivery_rate: '100 mL/hr',
              delivery_vtbi: '20 mL',
              total_vtbi: '1200 mL',
              time_remaining: '20.0 Hrs',
              schedule_control: '',
            },
          ],
        },
      },
      software: {
        title: 'SOFTWARE',
        content: 'SOFTWARE',
      },
      configure: {
        title: 'CONFIGURE',
        content: 'CONFIGURE',
      },
    },
  },
  {
    id: 2,
    title: 'Group2: 1200g Balances',
    summary: [
      {
        title: 'DEVICES',
        value: 3,
        classes: '',
      },
      {
        title: 'ACTIVE',
        value: 4,
        classes: '',
      },
      {
        title: 'IDLE',
        value: 0,
        classes: '',
      },
      {
        title: 'ERROR',
        value: 3,
        classes: 'text-danger',
      },
    ],
    tabs: {
      status: {
        title: 'STATUS',
        content: {
          headers: [
            {
              key: 'id',
              label: 'TK#',
            },
            {
              key: 'serial',
              label: 'Serial #',
            },
            {
              key: 'device_status',
              label: 'Device Status',
            },
            {
              key: 'test_queued',
              label: 'Test Queued',
            },
            {
              key: 'iv_set',
              label: 'IV Set',
            },
            {
              key: 'balance_max',
              label: 'Balance / MAX',
            },
            {
              key: 'software_loadout',
              label: 'Software Loadout',
            },
            {
              key: 'cal_date',
              label: 'Cal Date',
            },
            {
              key: 'disk_free',
              label: 'Disk Free',
            },
            {
              key: 'status_message',
              label: 'Status Message',
            },
            {
              key: 'data_age',
              label: 'Data Age',
            },
            {
              key: 'device_control',
              label: 'Device Control',
            },
          ],

          rows: [
            {
              id: '51',
              serial: '29001',
              device_status: 'DELIVERING',
              test_queued: '10 (20.0 hr)',
              iv_set: 'M6-V04-V11-S-6',
              balance_max: '1125.05 / 2000g',
              software_loadout: 'Current Main',
              cal_date: '2024/10/15',
              disk_free: '235MB',
              status_message: 'ERROR: Balance Max Threshold Hit',
              data_age: '8 sec',
              device_control: '',
            },
            {
              id: '52',
              serial: '29002',
              device_status: 'DELIVERING',
              test_queued: '10 (20.0 hr)',
              iv_set: 'M6-V04-V11-S-6',
              balance_max: '1125.05 / 2000g',
              software_loadout: 'Current Main',
              cal_date: '2024/10/15',
              disk_free: '235MB',
              status_message: 'ERROR: Balance Max Threshold Hit',
              data_age: '8 sec',
              device_control: '',
            },
            {
              id: '53',
              serial: '29003',
              device_status: 'DELIVERING',
              test_queued: '10 (20.0 hr)',
              iv_set: 'M6-V04-V11-S-6',
              balance_max: '1125.05 / 2000g',
              software_loadout: 'Current Main',
              cal_date: '2024/10/15',
              disk_free: '235MB',
              status_message: 'ERROR: Balance Max Threshold Hit',
              data_age: '8 sec',
              device_control: '',
            },
          ],
        },
      },
      schedule: {
        title: 'SCHEDULE',
        content: {
          headers: [
            {
              key: 'id',
              label: 'Schedule ID',
            },
            {
              key: 'status',
              label: 'Status',
            },
            {
              key: 'test_protocol',
              label: 'Test Protocol',
            },
            {
              key: 'tests',
              label: '# Tests',
            },
            {
              key: 'delivery_rate',
              label: 'Delivery Rate',
            },
            {
              key: 'delivery_vtbi',
              label: 'Delivery VTBI',
            },
            {
              key: 'total_vtbi',
              label: 'Total VTBI',
            },
            {
              key: 'time_remaining',
              label: 'Est. Time Remaining',
            },
            {
              key: 'schedule_control',
              label: 'Schedule Control',
            },
          ],

          rows: [
            {
              id: '5151',
              status: 'ACTIVE',
              test_protocol: 'Single Rate FRAT',
              tests: '10 (20.0 hr)',
              delivery_rate: '100 mL/hr',
              delivery_vtbi: '100 mL',
              total_vtbi: '1000 mL',
              time_remaining: '10.0 Hrs',
              schedule_control: '',
            },
            {
              id: '5155',
              status: 'ACTIVE',
              test_protocol: 'TIR-101',
              tests: '10 (20.0 hr)',
              delivery_rate: '100 mL/hr',
              delivery_vtbi: '20 mL',
              total_vtbi: '1200 mL',
              time_remaining: '20.0 Hrs',
              schedule_control: '',
            },
          ],
        },
      },
      software: {
        title: 'SOFTWARE',
        content: 'SOFTWARE',
      },
      configure: {
        title: 'CONFIGURE',
        content: 'CONFIGURE',
      },
    },
  },
];
