import { useAppSelector } from 'hooks/redux';
import { User } from 'interfaces/user';
import { FC } from 'react';
import { Navigate } from 'react-router-dom';
import { selectUser } from 'store/slices/auth';

type ProtectedRouteProps = {
  children: JSX.Element;
  roles: string[];
};

const ProtectedRoute: FC<ProtectedRouteProps> = ({ children, roles }) => {
  const user: User | undefined = useAppSelector(selectUser);

  if (!user?.role || !roles.includes(user.role.name)) {
    // If user role is not authorized for this route, redirect to unauthorized page
    return <Navigate to="/" />;
  }

  return children;
};

export default ProtectedRoute;
