import classNames from 'classnames';
import { MouseEvent, useRef, useEffect, useState, FC } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { RxTimer } from 'react-icons/rx';
import { SiCompilerexplorer } from 'react-icons/si';
import { MdOutlineMonitorHeart } from 'react-icons/md';
import { VscBeaker } from 'react-icons/vsc';

const ICONS = {
  LAB: <VscBeaker size={20} />,
  LAB_MONITOR: <MdOutlineMonitorHeart className="top-navbar__link-dropdown__link-icon" />,
  TEST_SCHEDULER: <RxTimer className="top-navbar__link-dropdown__link-icon" />,
  CONFIGURE_EQUIPMENT: <SiCompilerexplorer className="top-navbar__link-dropdown__link-icon" />,
};

export type LinkItem = {
  title: string;
  link: string;
  icon: string;
};

type LinkMenuProps = {
  title: string;
  icon: string;
  items: LinkItem[];
};

const LinkMenu: FC<LinkMenuProps> = ({ title, icon, items }) => {
  const ddEl = useRef<HTMLDivElement>(null);
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [isActive, setActive] = useState(false);

  // Close dropdown when click outside the dropdown
  const handleHtmlClick = (event: Event) => {
    // @ts-ignore
    if (!!ddEl && !ddEl.current.contains(event.target as any)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    const links = items.map((item: LinkItem) => item.link);
    setActive(links.includes(pathname));
  }, [pathname]);

  // Toggle the dropdown
  const handleToggle = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setIsOpen(!isOpen);
  };

  // Initial load
  useEffect(() => {
    const htmlEl: HTMLElement | null = document.querySelector('html');

    if (htmlEl) {
      htmlEl.addEventListener('click', handleHtmlClick);
    }

    return () => {
      if (htmlEl) {
        htmlEl.removeEventListener('click', handleHtmlClick);
      }
    };
  }, []);

  const classnames = classNames({
    'dropdown top-navbar__link-dropdown': true,
    active: isActive,
    open: isOpen,
  });

  return (
    <div className={classnames} ref={ddEl} data-testid="link-menu">
      <a href="/#" className="top-navbar__link-dropdown-link" onClick={handleToggle} data-testid="link-menu-toggle">
        <span className="top-navbar__link-dropdown-icon">{ICONS[icon as keyof typeof ICONS]}</span>
        <span className="top-navbar__link-dropdown-title">{title}</span>
      </a>
      <div className="top-navbar__link-dropdown-menu">
        <ul className="top-navbar__link-dropdown__list">
          {items.map((item: LinkItem) => (
            <li key={item.link}>
              <NavLink onClick={() => setIsOpen(false)} to={item.link} className="top-navbar__link-dropdown__link">
                {ICONS[item.icon as keyof typeof ICONS]}
                <span className="top-navbar__link-dropdown__link-text">{item.title}</span>
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default LinkMenu;
