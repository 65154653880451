import ContentHeader from 'components/UI/ContentHeader';

const TestScheduler = () => {
  return (
    <div>
      <ContentHeader title="Test Scheduler" />
    </div>
  );
};

export default TestScheduler;
