import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { loginRequest, msalInstance } from './msal';
import { InteractionRequiredAuthError } from '@azure/msal-browser';

const baseUrl = `${process.env.REACT_APP_BASE_URL}`;

const axiosInstance = axios.create({
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

export async function getToken(): Promise<string | undefined> {
  const accounts = msalInstance.getAllAccounts();

  try {
    const response = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0],
    });

    return `Bearer ${response.idToken}`;
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      msalInstance.acquireTokenRedirect(loginRequest);
      return Promise.reject(new Error('Redirecting for authentication'));
    } else {
      console.error('Token acquisition failed:', error);
      return Promise.reject(error);
    }
  }
}

const onRequest = async (config: InternalAxiosRequestConfig<any>): Promise<InternalAxiosRequestConfig<any>> => {
  const token = await getToken();
  config.headers.Authorization = token;
  return Promise.resolve(config);
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse) => {
  return response;
};

const onResponseError = async (error: AxiosError): Promise<AxiosError> => {
  if (error.response && error.response.status === 401) {
    await msalInstance.loginRedirect();
  }
  return Promise.reject(error);
};

axiosInstance.interceptors.request.use(onRequest, onRequestError);
axiosInstance.interceptors.response.use(onResponse, onResponseError);

export const baseURL = baseUrl;
export default axiosInstance;
