import { ColumnFilter, ColumnSort } from '@tanstack/react-table';
import axiosInstance from 'config/axios';
import { ContainerType } from 'interfaces/equipment/container-type';
import { PaginationResponse } from 'interfaces/pagination-response';

const BASE_URL = '/v1/equipment/container-types';

export type ContainerTypeResponse = PaginationResponse<ContainerType>;

const ContainerTypeService = {
  get: (queryParams: {
    pageIndex: number;
    pageSize: number;
    sorting?: ColumnSort[];
    columnFilters: ColumnFilter[];
  }) => {
    return axiosInstance.get<ContainerTypeResponse>(BASE_URL, {
      params: queryParams,
    });
  },

  update: (id: string | number, data: Partial<ContainerType>) => {
    return axiosInstance.patch<ContainerType>(`${BASE_URL}/${id}`, data);
  },

  create: (data: Partial<ContainerType>) => {
    return axiosInstance.post<ContainerType>(BASE_URL, data);
  },

  find: (id: string | number) => {
    return axiosInstance.get<ContainerType>(`${BASE_URL}/${id}`);
  },

  delete: (id: string | number) => {
    return axiosInstance.delete<ContainerType>(`${BASE_URL}/${id}`);
  },
};

export default ContainerTypeService;
