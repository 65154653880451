import React, { useEffect, useState, useCallback } from 'react';

const DebouncedInput = ({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) => {
  const [value, setValue] = useState(initialValue);

  // Update local state if initial value changes externally
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  // Debounce logic
  useEffect(() => {
    const handler = setTimeout(() => {
      if (value !== initialValue) {
        onChange(value);
      }
    }, debounce);

    return () => clearTimeout(handler);
  }, [value, debounce, initialValue, onChange]);

  // useCallback to memoize the handler
  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  }, []);

  return <input {...props} value={value} onChange={handleChange} />;
};

export default DebouncedInput;
