import React from 'react';
import ReactDOM from 'react-dom/client';
import './style/index.scss';
import reportWebVitals from './reportWebVitals';
import { RouterProvider } from 'react-router-dom';
import { router } from './router';
import { MsalProvider } from '@azure/msal-react';
import { msalInstance } from './config/msal';
import { Provider } from 'react-redux';
import store from 'store';
import ToasterNotifications from 'components/UI/ToasterNotifications';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [Sentry.browserTracingIntegration()],
  environment: process.env.REACT_APP_ENV,
  release: process.env.npm_package_version,
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
      retry: false,
    },
  },
});

root.render(
  // <React.StrictMode>
  <React.Suspense fallback="loading">
    <MsalProvider instance={msalInstance}>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <RouterProvider router={router} />
          <ToasterNotifications />
        </Provider>
      </QueryClientProvider>
    </MsalProvider>
  </React.Suspense>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
