import classNames from 'classnames';
import { MouseEvent, useRef, useEffect, useState } from 'react';
import { User } from 'interfaces/user';
import { IoIosPerson, IoIosLogOut } from 'react-icons/io';
// import { useQueryClient } from '@tanstack/react-query';
import { useMsal } from '@azure/msal-react';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { authActions, selectUser } from 'store/slices/auth';

const UserMenu = () => {
  const { instance } = useMsal();
  const user: User | undefined = useAppSelector(selectUser);
  const ddEl = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  // const queryClient = useQueryClient();
  const dispatch = useAppDispatch();

  // Sign user out and navigate
  const handleSignOut = async (e: MouseEvent) => {
    e.preventDefault();
    await dispatch(authActions.reset());
    instance.logoutRedirect();
  };

  // Close dropdown when click outside the dropdown
  const handleHtmlClick = (event: Event) => {
    // @ts-ignore
    if (!!ddEl && !ddEl.current.contains(event.target as any)) {
      setIsOpen(false);
    }
  };

  // Toggle the dropdown
  const handleToggle = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setIsOpen(!isOpen);
  };

  // Initial load
  useEffect(() => {
    const htmlEl: HTMLElement | null = document.querySelector('html');

    if (htmlEl) {
      htmlEl.addEventListener('click', handleHtmlClick);
    }

    return () => {
      if (htmlEl) {
        htmlEl.removeEventListener('click', handleHtmlClick);
      }
    };
  }, []);

  const classnames = classNames({
    'dropdown top-navbar__user-dropdown': true,
    open: isOpen,
  });

  return (
    <div className={classnames} ref={ddEl} data-testid="user-menu">
      <a href="/#" className="top-navbar__user-dropdown-link" onClick={handleToggle} data-testid="user-menu-toggle">
        <span>{user?.name}</span>
        <span>
          <span className="top-navbar__user-dropdown-icon">
            <IoIosPerson size={40} stroke={'green'} />
          </span>
        </span>
        {/* <i className="dl-user-6"></i> */}
      </a>
      <div className="top-navbar__user-dropdown-menu">
        <div className="top-navbar__user-dropdown-menu__header">
          <div className="top-navbar__user-dropdown-menu__header-wrapper">
            {/* <div className="top-navbar__user-dropdown-menu__header-title">
              Aleksandar&nbsp;Dukleski
            </div> */}
            <div className="top-navbar__user-dropdown-menu__header-subtitle">{user?.email}</div>
          </div>
        </div>
        <ul className="top-navbar__user-dropdown__list">
          <li>
            <a
              href="/#"
              className="top-navbar__user-dropdown__link"
              onClick={handleSignOut}
              data-testid="logout-button"
            >
              <IoIosLogOut className="top-navbar__user-dropdown__link-icon" />
              <span className="top-navbar__user-dropdown__link-text">Logout</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default UserMenu;
