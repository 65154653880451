import { FC } from 'react';

type GroupBoxProps = {
  title: string;
  summary: {
    title: string;
    value: string | number;
    classes?: string;
  }[];
  children: React.ReactNode;
  addBtn?: JSX.Element;
};

const GroupBox: FC<GroupBoxProps> = ({ title, summary, children, addBtn }) => {
  return (
    <section className="group-box mb-5">
      <div className="group-box__header d-flex px-4">
        <div className="group-box__header-title d-flex fs-3 align-items-center">{title}</div>
        <div
          className="group-box__header-summary d-flex ms-4 justify-content-between align-items-center"
          style={{ flex: 1 }}
        >
          <div className="d-flex">
            {Array.isArray(summary) &&
              summary.map((item) => {
                return (
                  <div
                    key={item.title}
                    className="group-box__header-summary--item  my-2 px-3 d-flex justify-content-center align-items-center flex-column"
                  >
                    <div className="group-box__header-summary--item-title">{item.title}</div>
                    <div className={`group-box__header-summary--item-value fs-4 ${item.classes}`}>{item.value}</div>
                  </div>
                );
              })}
          </div>
          {!!addBtn && addBtn}
        </div>
      </div>
      <div className="group-box__content">{children}</div>
    </section>
  );
};

export default GroupBox;
