import { FC } from 'react';
import { NavLink } from 'react-router-dom';

interface SideTabProps {
  links: { to: string; title: string }[];
}

export const SideTabsLinks: FC<SideTabProps> = ({ links }) => {
  return (
    <div className="side-tabs__buttons d-flex flex-column">
      {links.map((link) => (
        <NavLink key={link.to} to={link.to} className="side-tabs__link" data-test-id={`tab-${link.to}`}>
          {link.title}
        </NavLink>
      ))}
    </div>
  );
};
