import classnames from 'classnames';
import type { FC } from 'react';

type SpinnerProps = {
  loader?: 'loader' | 'loader-circle';
  isWhite?: boolean;
  isSmall?: boolean;
  isHidden?: boolean;
  isAbsolute?: boolean;
};

const Spinner: FC<SpinnerProps> = ({ isAbsolute, isHidden, isSmall, isWhite, loader = 'loader' }) => {
  const classNames = classnames({
    loader,
    white: isWhite,
    small: isSmall,
    hidden: isHidden,
    absolute: isAbsolute,
  });

  return (
    <div className={classNames} data-testid="spinner">
      Loading
    </div>
  );
};

export default Spinner;
