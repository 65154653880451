import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const SwalAlert = withReactContent(Swal);

export interface FireAlertProps {
  title: string;
  html?: string;
}
export interface FirePreConfirmAlertProps {
  title: string;
  html?: string;
  preConfirm: any;
  focusCancelBtn?: boolean;
}

export const fireAlert = ({ title, html }: FireAlertProps) => {
  return SwalAlert.fire({
    title,
    html,
    confirmButtonColor: '#0895DF',
    cancelButtonText: 'Cancel',
    confirmButtonText: 'Confirm',
    showCancelButton: true,
    showCloseButton: true,
  });
};

export const firePreConfirmAlert = ({ title, html, preConfirm, focusCancelBtn = true }: FirePreConfirmAlertProps) => {
  return SwalAlert.fire({
    title,
    html,
    confirmButtonColor: '#0895DF',
    cancelButtonText: 'Cancel',
    confirmButtonText: 'Confirm',
    showCancelButton: true,
    focusCancel: focusCancelBtn,
    showCloseButton: true,
    showLoaderOnConfirm: false,
    preConfirm,
  });
};

export default SwalAlert;
