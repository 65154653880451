import React, { useState, ReactNode } from 'react';

interface SideTabProps {
  isActive?: boolean;
  children: ReactNode;
  title: string;
  classes?: string;
}

interface TabsProps {
  children: React.ReactElement<SideTabProps>[];
}

export const SideTab: React.FC<SideTabProps> = ({ isActive, children, classes = '' }) => (
  <div className={classes} style={{ display: isActive ? 'block' : 'none' }}>
    {children}
  </div>
);

export const SideTabs: React.FC<TabsProps> = ({ children }) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div className="d-flex flex-row">
      <div className="side-tabs__buttons d-flex flex-column">
        {React.Children.map(children, (child, index) => (
          <div
            key={index}
            className={`side-tabs__link ${activeTab === index ? 'active' : ''}`}
            onClick={() => setActiveTab(index)}
            data-testid={`tab-${child.props.title}-${index}`}
          >
            {child.props.title}
          </div>
        ))}
      </div>
      <div className="flex-grow-1 side-tabs__content">
        {React.Children.map(children, (child, index) => React.cloneElement(child, { isActive: activeTab === index }))}
      </div>
    </div>
  );
};
