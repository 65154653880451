import GroupBox from 'components/UI/GroupBox/GroupBox';
import { SideTabsLinks } from 'components/UI/GroupBox/SideTabsLinks';
import { EQUIPMENT_LINKS } from 'constants/equipment-links';

const IVSets = () => {
  return (
    <div>
      <GroupBox
        title="CONFIG: IV Sets"
        summary={[
          {
            title: 'TEST',
            value: 5,
            classes: '',
          },
        ]}
      >
        <div className="d-flex flex-row">
          <SideTabsLinks links={EQUIPMENT_LINKS} />
          <div className="flex-grow-1 side-tabs__content">IVSets</div>
        </div>
      </GroupBox>
    </div>
  );
};

export default IVSets;
