import { createBrowserRouter, Navigate } from 'react-router-dom';
import AdminLayout from './layout/AdminLayout';
import Dashboard from './pages/Dashboard';
import NotFound from 'pages/NotFound';
import ProtectedRoute from 'components/guards/ProtectedRoute';
import Users from 'pages/Users/Users';
import { ROLE_ADMIN, ROLE_LAB_TECHNICIAN } from 'constants/roles';
import Settings from 'pages/Settings';
import UsersModalForm from 'pages/Users/UsersModalForm';
import ModalSidebar from 'components/UI/ModalSidebar';
import LabMonitor from 'pages/Lab/LabMonitor/LabMonitor';
import TestScheduler from 'pages/Lab/TestScheduler';
import ConfigureEquipment from 'pages/Lab/Equipment/ConfigureEquipment';
import NeedleTypes from 'pages/Lab/Equipment/Needles/NeedleTypes';
import FluidTypes from 'pages/Lab/Equipment/FluidTypes/FluidTypes';
import FluidTypesModalForm from 'pages/Lab/Equipment/FluidTypes/FluidTypesModalForm';
import FluidSources from 'pages/Lab/Equipment/FluidSources/FluidSources';
import ContainerTypes from 'pages/Lab/Equipment/Containers/ContainerTypes';
import IVSets from 'pages/Lab/Equipment/IVSets/IVSets';
import InfusionDevices from 'pages/Lab/Equipment/InfusionDevice/InfusionDevices';
import Balances from 'pages/Lab/Equipment/Balances/Balances';
import NeedleTypesModalForm from 'pages/Lab/Equipment/Needles/NeedleTypesModalForm';
import ContainerTypesModalForm from 'pages/Lab/Equipment/Containers/ContainerTypesModalForm';
import FluidSourcesModalForm from 'pages/Lab/Equipment/FluidSources/FluidSourcesModalForm';

export const router = createBrowserRouter([
  {
    id: 'root',
    path: '/',
    element: <AdminLayout />,
    children: [
      {
        path: '',
        element: <Dashboard />,
      },
      {
        path: 'lab-monitor',
        element: (
          <ProtectedRoute roles={[ROLE_ADMIN, ROLE_LAB_TECHNICIAN]}>
            <LabMonitor />
          </ProtectedRoute>
        ),
      },
      {
        path: 'test-scheduler',
        element: (
          <ProtectedRoute roles={[ROLE_ADMIN, ROLE_LAB_TECHNICIAN]}>
            <TestScheduler />
          </ProtectedRoute>
        ),
      },
      {
        path: `configure-equipment`,
        element: (
          <ProtectedRoute roles={[ROLE_ADMIN, ROLE_LAB_TECHNICIAN]}>
            <ConfigureEquipment />
          </ProtectedRoute>
        ),
        children: [
          {
            // Redirect to 'needle-types' by default
            index: true,
            element: <Navigate to="needle-types" />,
          },
          {
            path: 'needle-types',
            element: <NeedleTypes />,
            children: [
              {
                path: ':id',
                element: (
                  <ModalSidebar title="Edit Needle Type">
                    <NeedleTypesModalForm />
                  </ModalSidebar>
                ),
              },
              {
                path: 'add',
                element: (
                  <ModalSidebar title="Add Needle Type">
                    <NeedleTypesModalForm />
                  </ModalSidebar>
                ),
              },
            ],
          },
          {
            path: 'fluid-types',
            element: <FluidTypes />,
            children: [
              {
                path: ':id',
                element: (
                  <ModalSidebar title="Edit Fluid Type">
                    <FluidTypesModalForm />
                  </ModalSidebar>
                ),
              },
              {
                path: 'add',
                element: (
                  <ModalSidebar title="Add Fluid Type">
                    <FluidTypesModalForm />
                  </ModalSidebar>
                ),
              },
            ],
          },
          {
            path: 'fluid-sources',
            element: <FluidSources />,
            children: [
              {
                path: ':id',
                element: (
                  <ModalSidebar title="Edit Fluid Source">
                    <FluidSourcesModalForm />
                  </ModalSidebar>
                ),
              },
              {
                path: 'add',
                element: (
                  <ModalSidebar title="Add Fluid Source">
                    <FluidSourcesModalForm />
                  </ModalSidebar>
                ),
              },
            ],
          },
          {
            path: 'container-types',
            element: <ContainerTypes />,
            children: [
              {
                path: ':id',
                element: (
                  <ModalSidebar title="Edit Container Type">
                    <ContainerTypesModalForm />
                  </ModalSidebar>
                ),
              },
              {
                path: 'add',
                element: (
                  <ModalSidebar title="Add Container Type">
                    <ContainerTypesModalForm />
                  </ModalSidebar>
                ),
              },
            ],
          },
          {
            path: 'iv-sets',
            element: <IVSets />,
          },
          {
            path: 'infusion-devices',
            element: <InfusionDevices />,
          },
          {
            path: 'balances',
            element: <Balances />,
          },
        ],
      },
      {
        path: 'settings',
        element: (
          <ProtectedRoute roles={[ROLE_ADMIN]}>
            <Settings />
          </ProtectedRoute>
        ),
        children: [
          {
            path: 'users',
            element: (
              <ProtectedRoute roles={[ROLE_ADMIN]}>
                <Users />
              </ProtectedRoute>
            ),
            children: [
              {
                path: ':id',
                element: (
                  <ModalSidebar title="Edit User">
                    <UsersModalForm />
                  </ModalSidebar>
                ),
              },
            ],
          },
        ],
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
]);
