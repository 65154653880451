import { FC, useEffect, useRef, useState } from 'react';

type DropdownAction = {
  label: string;
  value: number | string;
};

type DropdownButtonProps = {
  label: string;
  onItemSelect?: (item: string | number) => void;
  actions: DropdownAction[];
};

const DropdownButton: FC<DropdownButtonProps> = ({ label, onItemSelect, actions }) => {
  const [isOpen, setIsOpen] = useState(false);
  const ddEl = useRef<HTMLDivElement>(null);

  // Close dropdown when click outside the dropdown
  const handleHtmlClick = (event: Event) => {
    if (!!ddEl && !ddEl?.current?.contains(event.target as any)) {
      setIsOpen(false);
    }
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // Initial load
  useEffect(() => {
    const htmlEl: HTMLElement | null = document.querySelector('html');

    if (htmlEl) {
      htmlEl.addEventListener('click', handleHtmlClick);
    }

    return () => {
      if (htmlEl) {
        htmlEl.removeEventListener('click', handleHtmlClick);
      }
    };
  }, []);

  const handleItemClick = (event: React.MouseEvent, value: string | number) => {
    event.preventDefault();
    event.stopPropagation();

    if (onItemSelect) {
      onItemSelect(value);
    }

    setIsOpen(false);
  };

  return (
    <div className="dropdown" ref={ddEl}>
      <button
        className="btn btn-primary dropdown-toggle"
        type="button"
        id="dropdownMenuButton1"
        onClick={toggleDropdown}
        aria-expanded={isOpen}
      >
        {label}
      </button>
      {isOpen && (
        <ul className="dropdown-menu d-block" aria-labelledby="dropdownMenuButton1">
          {actions?.map((action) => {
            return (
              <li key={action.value}>
                <a className="dropdown-item" href="#" onClick={(e) => handleItemClick(e, action.value)}>
                  {action.label}
                </a>
              </li>
            );
          })}
          {/* <li>
            <a className="dropdown-item" href="#">
              Add Schedule
            </a>
          </li>
          <li>
            <a className="dropdown-item" href="#">
              Resume Paused
            </a>
          </li>
          <li>
            <a className="dropdown-item" href="#">
              Pause Scheduled
            </a>
          </li>
          <li>
            <a className="dropdown-item" href="#">
              Cancel Scheduled
            </a>
          </li>
          <li>
            <a className="dropdown-item" href="#">
              HALT
            </a> */}
          {/* </li> */}
        </ul>
      )}
    </div>
  );
};

export default DropdownButton;
